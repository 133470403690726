import React from 'react';
import { IBlockData } from '@core/typings';
import { BasicType } from '@core/constants';
import { createBlock } from '@core/utils/createBlock';
import { mergeBlock } from '@core/utils/mergeBlock';
import { t } from '@core/utils';
import { BasicBlock } from '@core/components/BasicBlock';

export type ICampaignPdf = IBlockData<
  {
    'background-color'?: string;
    'background-height'?: string;
    'background-position'?: string;
    'background-url'?: string;
    'background-size'?: string;
    'background-width'?: string;
    'vertical-align'?: string;
    'border-radius'?: string;
    width?: string;
    height?: string;
    mode: 'fluid-height' | 'fixed-height';
    padding?: string;
  },
  {}
>;

export const CampaignPdf = createBlock<ICampaignPdf>({
  get name() {
    return t('View Product');
  },
  type: BasicType.CAMPAIGN_PDF,
  create: (payload) => {
    const defaultData: ICampaignPdf = {
      type: BasicType.CAMPAIGN_PDF,
      data: {
        value: {},
      },
      attributes: {
        'background-color': '#ffffff',
        'background-position': 'center top',
        'background-size': 'cover',
        mode: 'fluid-height',
        padding: '10px 0px 10px 0px',
        'vertical-align': 'top',
      },
      children: [
        {
          type: BasicType.UNSUBSCRIBE_BUTTON,
          data: {
            value: {
              links: [
                {
                  content: '-campaign-message-',
                  color: '#000000',
                  'font-size': '13px',
                  //target: '_blank',
                  padding: '0px 0px',
                  'text-transform': 'none',
                },
              ],
            },
          },
          attributes: {
            align: 'center',
          },
          children: [],
        },
        {
          type: BasicType.CAMPAIGN_BUTTON,
          data: {
            value: {
              content: 'Click here to view products',
            },
          },
          attributes: {
            align: 'center',
            'background-color': '#414141',
            color: '#ffffff',
            'font-weight': 'normal',
            'border-radius': '3px',
            padding: '10px 25px 10px 25px',
            'inner-padding': '10px 25px 10px 25px',
            'line-height': '120%',
            target: '_blank',
            'vertical-align': 'middle',
            border: 'none',
            'text-align': 'center',
            href: '-product_link-',
          },
          children: [],
        }
      ],
    };
    return mergeBlock(defaultData, payload);
  },
  validParentType: [BasicType.PAGE, BasicType.WRAPPER],
  render(params) {
    return <BasicBlock params={params} tag="mj-hero" />;
  },
});
