// import React from 'react';
// import { IBlock, IBlockData } from '@core/typings';
// import { BasicType } from '@core/constants';
// import { createBlock } from '@core/utils/createBlock';
// import { getImg } from '@core/utils/getImg';
// import { mergeBlock } from '@core/utils/mergeBlock';
// import { t } from '@core/utils';
// import { BasicBlock } from '@core/components/BasicBlock';

import React from 'react';
import { IBlock, IBlockData } from '@core/typings';
import { BasicType } from '@core/constants';
import { createBlock } from '@core/utils/createBlock';
import { mergeBlock } from '@core/utils/mergeBlock';
import { t } from '@core/utils';
import { BasicBlock } from '@core/components/BasicBlock';


// export type IUnsubcribe = IBlockData<
//   {
//     align?: string;
//     color?: string;
//     'container-background-color'?: string;
//     'border-radius'?: string;
//     'icon-height'?: string;
//     'icon-size'?: string;
//     mode?: 'vertical' | 'horizontal';
//     'icon-padding': string;
//     'text-padding': string;
//     'text-decoration'?: string;
//     padding?: string;
//     'inner-padding'?: string;
//     'font-family'?: string;
//     'font-size'?: string;
//     'font-style'?: string;
//     'font-weight'?: string;
//     'line-height'?: string;
//   },
//   {
//     elements: Array<{
//       content: string;
//       content1: string;
//       src: string;
//       align?: string;
//       alt?: string;
//       'background-color'?: string;
//       'border-radius'?: string;
//       color?: string;
//       'font-family'?: string;
//       'font-size'?: string;
//       'font-style'?: string;
//       'font-weight'?: string;
//       href?: string;
//       'icon-height'?: string;
//       'icon-size'?: string;
//       'line-height'?: string;
//       name?: string;
//       padding?: string;
//       'icon-padding'?: string;
//       'text-padding'?: string;
//       target?: string;
//       title?: string;
//       'text-decoration'?: string;
//       'vertical-align'?: string;
//     }>;
//   }
// >;

export type IUnsubcribe = IBlockData<
  {
    align?: string;
    hamburger?: string;
    'ico-align'?: string;
    'ico-color'?: string;
    'ico-font-size'?: string;
    'ico-line-height'?: string;
    'ico-padding'?: string;
    'ico-text-decoration'?: string;
    'ico-text-transform'?: string;
  },
  {
    links: Array<{
      content: string;
      color?: string;
      href?: string;
      'font-family'?: string;
      'font-size'?: string;
      'font-style'?: string;
      'font-weight'?: string;
      'line-height'?: string;
      'text-decoration'?: string;
      'text-transform'?: string;
      target?: string;
      padding?: string;
    }>;
  }
>;

// export const Unsubscribe: IBlock<IUnsubcribe> = createBlock({
//   get name() {
//     return t('Unsubscribe');
//   },
//   type: BasicType.UNSUBSCRIBE_BUTTON,
//   create: (payload) => {
//     const defaultData: IUnsubcribe = {
//       type: BasicType.UNSUBSCRIBE_BUTTON,
//       data: {
//         value: {
//           elements: [
//             {
//               content: 'No longer want to receive these email? You can Unsubscribe',
//               content1: 'No longer want to receive these email? You can Unsubscribe',
//               href: '#',
//               target: '_blank',
//               src: getImg('IMAGE_02'),
//             },

//           ],
//         },
//       },
//       attributes: {
//         align: 'left',
//         color: '#333333',
//         mode: 'horizontal',
//         'font-size': '13px',
//         'font-weight': 'normal',
//         'border-radius': '3px',
//         padding: '10px 25px 10px 25px',
//         'inner-padding': '4px 4px 4px 4px',
//         'line-height': '22px',
//         'text-padding': '4px 4px 4px 0px',
//         'icon-padding': '0px',
//         'icon-size': '20px',
//       },
//       children: [],
//     };
//     return mergeBlock(defaultData, payload);
//   },
//   validParentType: [BasicType.COLUMN],
//   render(params) {
//     const { data } = params;
//     const elements = (data).data.value.elements
//       .map((element) => {
//         const elementAttributeStr = Object.keys(element)
//           .filter((key) => key !== 'content' && element[key as keyof typeof element] !== '') // filter att=""
//           .map((key) => `${key}="${element[key as keyof typeof element]}"`)
//           .join(' ');
//         return `
//           <mj-social-element ${elementAttributeStr}>${element.content}</mj-social-element>
//           `;
//       })
//       .join('\n');
//     return <BasicBlock params={params} tag="mj-social">{elements}</BasicBlock>;

//   },
// });

export const Unsubscribe: IBlock<IUnsubcribe> = createBlock({
  get name() {
    return t('Unsubscribe');
  },
  type: BasicType.UNSUBSCRIBE_BUTTON,
  create: (payload) => {
    const defaultData: IUnsubcribe = {
      type: BasicType.UNSUBSCRIBE_BUTTON,
      data: {
        value: {
          links: [
            {
              //href: '/gettings-started-onboard',
              content: 'No longer want to receive these email? You can',
              color: '#000000',
              'font-size': '13px',
              //target: '_blank',
              padding: '0px 0px',
              'text-transform': 'none',
            },
            {
              href: '-unsubscribe_link-',
              content: 'Unsubscribe',
              color: '#1890ff',
              'font-size': '13px',
              target: '_blank',
              padding: '0px 5px',
              'text-transform': 'none',
              'text-decoration': 'underline',
            },
          ],
        },
      },
      attributes: {
        align: 'center',
      },
      children: [],
    };
    return mergeBlock(defaultData, payload);
  },
  validParentType: [BasicType.COLUMN, BasicType.HERO],

  render(params) {
    const { data } = params;
    const links = (data).data.value.links
      .map((link, index) => {
        const linkAttributeStr = Object.keys(link)
          .filter((key) => key !== 'content' && link[key as keyof typeof link] !== '') // filter att=""
          .map((key) => `${key}="${link[key as keyof typeof link]}"`)
          .join(' ');
        return `
          <mj-navbar-link ${linkAttributeStr}>${link.content}</mj-navbar-link>
          `;
      })
      .join('\n');
    return <BasicBlock params={params} tag="mj-navbar">{links}</BasicBlock>;

  },
});
